/* website: 2457-morreynissanburnaby2
 * created at 2022-04-14 11:30 fbbreard
 */

// Import all makes and organization styles files
@import "../templates/makes/nissan.scss";
@import "../utils/icons.scss";

.widget-sr{
    @include clearfix;
    width: 100%;
    &.dealer__morrey-nissan-burnaby{
        .listing-tile .vehicle-certification .certification-badge-carfax{
            flex-direction: row;
            align-items: center;
        }
        .listing-tile .certification-badge__image{
            align-self: center;
        }
        .carproof-badge{
            width: 100%;
        }
    }
}
